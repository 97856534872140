import {useMemo, useState} from 'react';
import styled from 'styled-components';

import Canvas from './canvas';
import BalancedText from './components/balancedText';
import NumberField from './components/fields/number';
import IconDone from './icons/done';

const inputProps = {
  inputWidth: '2.25rem',
  rowLayout: true,
  step: 0.05,
  minValue: 0,
  maxValue: 1,
};

function App() {
  const [population, setPopulation] = useState({error: 500, performance: 5000});
  function onPopulationChange(name: keyof typeof population, value: number) {
    setPopulation({
      ...population,
      [name]: value,
    });
  }

  const [filterRates, setFilterRates] = useState({
    sampleRate: 1,
    traceSampleRate: 0.5,
    serverError: 1,
    serverPerformance: 0.25,
    serverReleaseV1: 0.5,
    serverReleaseV2: 1,
    serverEnvironmentProd: 1,
    serverEnvironmentStage: 0.25,
  });
  function onFilterRateChange(name: keyof typeof filterRates, value: number) {
    setFilterRates({
      ...filterRates,
      [name]: value,
    });
  }

  const [inboundFilters, setInboundFilters] = useState({
    localhost: true,
    browserExtensions: true,
    webCrawlers: true,
    legacyBrowsers: true,
  });
  function onInboundFilterChange(name: keyof typeof inboundFilters, value: boolean) {
    setInboundFilters({
      ...inboundFilters,
      [name]: value,
    });
  }

  const inboundFilterRate = useMemo(
    () =>
      1 -
      (inboundFilters.localhost ? 0.01 : 0) -
      (inboundFilters.browserExtensions ? 0.01 : 0) -
      (inboundFilters.webCrawlers ? 0.01 : 0) -
      (inboundFilters.legacyBrowsers ? 0.04 : 0),
    [inboundFilters]
  );

  return (
    <Wrap>
      <InnerWrap>
        <SiteTitle>SenKey</SiteTitle>
        <SiteDescription>
          <BalancedText>
            An overview of{' '}
            <a href="https://www.sentry.io/welcome" target="_blank" rel="noreferrer">
              Sentry’s
            </a>{' '}
            event sampling pipeline, from client to server side
          </BalancedText>
        </SiteDescription>
        <SamplingGrid>
          <Column>
            <SectionTitle>Initial Population</SectionTitle>
            <SectionDescription>
              <BalancedText>
                All error/transaction events detected by the Sentry&nbsp;SDK
              </BalancedText>
            </SectionDescription>
          </Column>
          <Column>
            <SectionTitle>Client-Side Sampling</SectionTitle>
            <SectionDescription>
              <BalancedText>
                Filtering in the client side before events are sent to
                Sentry’s&nbsp;servers
              </BalancedText>
            </SectionDescription>
          </Column>
          <Column>
            <SectionTitle>Server-Side Sampling</SectionTitle>
            <SectionDescription style={{width: 'calc(100% - 7rem)'}}>
              <BalancedText>
                Additional filtering within Sentry’s servers (configurable in the
                Sentry&nbsp;app)
              </BalancedText>
            </SectionDescription>
          </Column>
        </SamplingGrid>
        <Canvas
          population={population}
          filterRates={filterRates}
          inboundFilterRate={inboundFilterRate}
        />
        <SamplingGrid>
          <Column>
            <NumberField
              rowLayout
              step={1}
              minValue={1}
              inputWidth="3rem"
              label="Errors"
              value={population.error}
              onChange={val => onPopulationChange('error', val)}
            />
            <NumberField
              rowLayout
              step={1}
              minValue={1}
              inputWidth="3rem"
              label="Transactions"
              value={population.performance}
              onChange={val => onPopulationChange('performance', val)}
            />
          </Column>
          <Column>
            <NumberField
              label="sampleRate"
              description="Error sample rate"
              value={filterRates.sampleRate}
              onChange={val => onFilterRateChange('sampleRate', val)}
              {...inputProps}
            />
            <NumberField
              label="traceSampleRate"
              description="Transaction sample rate"
              value={filterRates.traceSampleRate}
              onChange={val => onFilterRateChange('traceSampleRate', val)}
              {...inputProps}
            />
          </Column>
          <Column>
            <SectionSubtitle>Inbound Data Filters</SectionSubtitle>
            <CheckboxLabel>
              <CheckInput
                type="checkbox"
                checked={inboundFilters.legacyBrowsers}
                onChange={e => onInboundFilterChange('legacyBrowsers', e.target.checked)}
              />
              <Checkbox checked={inboundFilters.legacyBrowsers}>
                <IconDone />
              </Checkbox>
              Legacy browsers
            </CheckboxLabel>
            <CheckboxLabel>
              <CheckInput
                type="checkbox"
                checked={inboundFilters.browserExtensions}
                onChange={e =>
                  onInboundFilterChange('browserExtensions', e.target.checked)
                }
              />
              <Checkbox checked={inboundFilters.browserExtensions}>
                <IconDone />
              </Checkbox>
              Browser extensions
            </CheckboxLabel>
            <CheckboxLabel>
              <CheckInput
                type="checkbox"
                checked={inboundFilters.webCrawlers}
                onChange={e => onInboundFilterChange('webCrawlers', e.target.checked)}
              />
              <Checkbox checked={inboundFilters.webCrawlers}>
                <IconDone />
              </Checkbox>
              Web crawlers
            </CheckboxLabel>
            <CheckboxLabel>
              <CheckInput
                type="checkbox"
                checked={inboundFilters.localhost}
                onChange={e => onInboundFilterChange('localhost', e.target.checked)}
              />
              <Checkbox checked={inboundFilters.localhost}>
                <IconDone />
              </Checkbox>
              localhost
            </CheckboxLabel>
            <SectionSubtitle>Custom Rules</SectionSubtitle>
            <NumberField
              label="All incoming errors"
              description="Not available yet"
              value={filterRates.serverError}
              onChange={val => onFilterRateChange('serverError', val)}
              isDisabled
              {...inputProps}
            />
            <NumberField
              label="All incoming transactions"
              value={filterRates.serverPerformance}
              onChange={val => onFilterRateChange('serverPerformance', val)}
              {...inputProps}
            />
            <NumberField
              label="Release = v1"
              description="Transactions from release V1"
              value={filterRates.serverReleaseV1}
              onChange={val => onFilterRateChange('serverReleaseV1', val)}
              {...inputProps}
            />
            <NumberField
              label="Release = v2"
              description="Transactions from release V2"
              value={filterRates.serverReleaseV2}
              onChange={val => onFilterRateChange('serverReleaseV2', val)}
              {...inputProps}
            />
            <NumberField
              label="Environment = prod"
              description="Transactions from prod"
              value={filterRates.serverEnvironmentProd}
              onChange={val => onFilterRateChange('serverEnvironmentProd', val)}
              {...inputProps}
            />
            <NumberField
              label="Environment = stage"
              description="Transactions from stage"
              value={filterRates.serverEnvironmentStage}
              onChange={val => onFilterRateChange('serverEnvironmentStage', val)}
              {...inputProps}
            />
            <CustomRulesNote>…more custom rules can be added in Sentry</CustomRulesNote>
          </Column>
        </SamplingGrid>
      </InnerWrap>

      <Footer>Made by Vu Luong and Dora Tamsen</Footer>
    </Wrap>
  );
}

export default App;

const Wrap = styled('div')`
  max-width: 68rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - ${p => p.theme.space[4]} * 2);

  @media only screen and (max-width: ${p => p.theme.breakpoints.medium}) {
    width: calc(${p => p.theme.breakpoints.small} + ${p => p.theme.space[4]} * 2);
    min-height: calc(100vh - ${p => p.theme.space[4]});
  }
`;

const InnerWrap = styled('div')`
  overflow: hidden;
`;

const SiteTitle = styled('h1')`
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: ${p => p.theme.space[0.5]};
`;

const SiteDescription = styled('p')`
  color: ${p => p.theme.subText};
  margin-bottom: ${p => p.theme.space[4]};
  max-width: 18rem;

  a {
    color: ${p => p.theme.subText};
    text-decoration-color: ${p => p.theme.border};
  }
  a:hover {
    text-decoration-color: ${p => p.theme.subText};
  }
`;

const SamplingGrid = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns:
    calc((100% - 7rem) * 0.3 - ${p => p.theme.space[3]} - 2px) calc((100% - 7rem) * 0.3)
    1fr;
  margin-bottom: ${p => p.theme.space[1]};
`;

const Column = styled('div')`
  &:not(:last-child) {
    position: relative;
    padding-right: ${p => p.theme.space[3]};
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: -100em;
      border-right: solid 1px ${p => p.theme.innerBorder};
    }
  }
  &:not(:first-child) {
    padding-left: ${p => p.theme.space[3]};
  }
`;

const SectionTitle = styled('h2')`
  font-size: ${p => p.theme.fontSizeLarge};
  margin-bottom: ${p => p.theme.space[0.5]};
`;

const SectionDescription = styled('p')`
  color: ${p => p.theme.subText};
  font-size: ${p => p.theme.fontSizeSmall};
  margin-bottom: ${p => p.theme.space[4]};
  max-width: 16rem;
`;

const SectionSubtitle = styled('h3')`
  font-size: ${p => p.theme.fontSizeSmall};
  color: ${p => p.theme.subText};
  text-transform: uppercase;

  margin-bottom: 0;
  &:not(:first-of-type) {
    margin-top: ${p => p.theme.space[4]};
  }
  &:first-of-type {
    margin-top: ${p => p.theme.space[2]};
  }
`;

const CheckboxLabel = styled('label')`
  display: flex;
  margin: ${p => p.theme.space[1]} 0;
  cursor: pointer;
  &:first-of-type {
    padding-top: ${p => p.theme.space[0.5]};
  }
`;

const CheckInput = styled('input')`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;

const Checkbox = styled('div')<{checked: boolean}>`
  width: 1rem;
  height: 1rem;
  border-radius: calc(${p => p.theme.borderRadius} / 2);
  margin-right: ${p => p.theme.space[1]};
  border: solid 1px ${p => p.theme.border};

  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;

  ${p =>
    p.checked &&
    `
    background-color: ${p.theme.active};
    border-color: ${p.theme.active};
    color: ${p.theme.white};
  `}
`;

const CustomRulesNote = styled('p')`
  padding-top: ${p => p.theme.space[1.5]};
  color: ${p => p.theme.subText};
`;

const Footer = styled('p')`
  font-size: ${p => p.theme.fontSizeSmall};
  color: ${p => p.theme.subText};

  margin-top: calc(${p => p.theme.space[4]} * 2);
  padding: ${p => p.theme.space[1]} 0;
  border-top: 1px solid ${p => p.theme.innerBorder};
`;
